<template>
  <div v-show="couponChooseVisible" class="refueling-coupon-choose">
    <van-action-sheet
      v-model="couponChooseVisible"
      title="优惠券"
    >
      <div class="coupon-container">
        <template v-if="couponList.length > 0">
          <div class="coupon-list">
            <div v-for="(item, index) in couponList" :key="index" class="coupon" @click="handleSelectCoupon(item)">
              <div class="coupon-info">
                <div v-if="item.promotionType === 1">
                  <div class="price">
                    <span>￥</span>{{ item.discount }}
                  </div>
                  <div class="tips-use">
                    满{{ item.amount }}可用
                  </div>
                </div>
                <div v-if="item.promotionType === 2">
                  <div class="price">
                    <span>￥</span>{{ item.discount }}
                  </div>
                  <div class="tips-use">
                    立减
                  </div>
                </div>
                <div v-if="item.promotionType === 3">
                  <div class="price">
                    {{ item.discount }}<span>折</span>
                  </div>
                  <div class="tips-use">
                    折扣
                  </div>
                </div>
              </div>
              <div class="coupon-other-info">
                <div class="coupon-title">{{ item.promotionDesc }}</div>
                <div class="coupon-time">
                  有效期至{{ item.endTimestamp }}
                </div>
              </div>
              <div class="check">
                <van-icon v-if="item.allowanceId === couponId" name="checked" />
                <span v-else class="circle" />
              </div>
            </div>
          </div>
          <div class="coupon-btn">
            <van-button
              :disabled="couponList.length === 0"
              color="#F7263C"
              size="small"
              block
              @click="handleConfirmCoupon"
            >
              确定
            </van-button>
          </div>
        </template>
        <template v-else>
          <div class="coupon-null">
            <van-empty description="暂无优惠券" image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-05-28/16/yuelvhui4zJQuZvXpn1622192373.png" />
          </div>
        </template>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getOrderPromotionList } from '@/services/comeOn'
import { ActionSheet as VanActionSheet, Button as VanButton, Empty as VanEmpty, Icon as VanIcon } from 'vant'

export default {
  name: 'RefuelingCouponChoose',
  components: {
    VanEmpty,
    VanActionSheet,
    VanButton,
    VanIcon
  },
  data() {
    return {
      couponChooseVisible: false,
      couponId: null, // 选中的优惠券id
      couponInfo: null, // 选中的优惠券信息
      storeId: null, // 油站ID
      amount: null, // 购买金额
      couponList: []
    }
  },
  methods: {
    /**
     * 处理打开模态框
     * 2021-08-13 09:31:44
     * @author SnowRock
     */
    async handleOpen({ gasId: storeId, amount, couponId }) {
      this.storeId = storeId
      this.amount = amount
      this.couponId = couponId
      await this.getCouponByRefuelingList()
      this.couponChooseVisible = true
    },
    /**
     * 获取加油优惠券列表
     * 2021-08-13 09:27:00
     * @author SnowRock
     */
    async getCouponByRefuelingList() {
      const { storeId, amount, couponId } = this
      const mid = localStorage.getItem('uid')
      const mobile = localStorage.getItem('mobile')
      await getOrderPromotionList({
        mid,
        mobile,
        storeId,
        amount
      }).then(res => {
        const { code, data = [] } = res
        if (code === 200) {
          this.couponList = data

          if (couponId) {
            this.couponInfo = this.couponList.filter(i => {
              return i.allowanceId === couponId
            })[0] || null
          }
        }
      })
    },
    /**
     * 处理选择优惠券
     * 2021-08-13 09:25:40
     * @author SnowRock
     */
    handleSelectCoupon(couponInfo) {
      const { allowanceId } = couponInfo
      if (this.couponId !== allowanceId) {
        this.couponId = allowanceId
        this.couponInfo = couponInfo
      } else {
        this.couponId = null
        this.couponInfo = null
      }
    },
    /**
     * 处理选择优惠券
     * 2021-08-13 09:25:40
     * @author SnowRock
     */
    handleConfirmCoupon() {
      const { couponId, couponInfo } = this
      this.couponChooseVisible = false
      this.$emit('handleChooseCoupon', {
        couponId,
        ...couponInfo
      })
    }
  }
}
</script>

<style lang="less" scoped>
.refueling-coupon-choose {
  position: fixed;
  z-index: 100;

  ::v-deep .van-popup {
  }

  .coupon-container {
    width: 100%;
    padding: 0 12px 20px;

    .coupon-list {
      width: 100%;
      padding-bottom: 18px;
      .coupon {
        width: 100%;
        padding: 17px 0;
        background: linear-gradient(90deg, #fd6a7a, #f7263c);
        margin-bottom: 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        color: #fff;
      }
      .coupon-info {
        flex: 104px;
        width: 104px;
        min-width: 104px;
        text-align: center;
        box-sizing: border-box;
        font-family: PingFangSC, PingFangSC-Regular, sans-serif;
        padding: 4px 0;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        .price {
          font-size: 22px;
          span {
            font-size: 14px;
          }
        }
        .tips-use {
          font-size: 12px;
          margin-top: 5px;
        }
      }
      .coupon-other-info {
        flex: calc(100% - 154px);
        width: calc(100% - 154px);
        max-width: calc(100% - 154px);
        padding-left: 24px;
        .coupon-title {
          width: 100%;
          font-size: 20px;
          line-height: 28px;
          margin-top: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-time {
          margin-top: 6px;
          font-size: 12px;
        }
      }
      .check {
        flex: 50px;
        min-width: 20px;
        height: 20px;
        font-size: 20px;
        text-align: center;
        .circle {
          width: 20px;
          height: 20px;
          display: inline-block;
          border-radius: 50%;
          box-sizing: border-box;
          border: 2px solid #fff;
          margin-bottom: 0;
        }
      }
    }
  }
}

</style>
