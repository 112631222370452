<template>
  <div v-show="actionSheetVisible" class="choose-petrol-gun-number">
    <van-action-sheet
      v-model="actionSheetVisible"
      title="请选择油号/枪号"
    >
      <div class="sku-content">
        <div v-if="goods && goods.length > 0" class="sku-attr">
          <div class="sku-label">全部商品</div>
          <div class="skus">
            <div
              v-for="(i, j) in goods"
              :key="'goods' + j"
              class="sku"
              :class="{
                'choose': petrolTypeId === i.id
              }"
              @click="handleChoosePetrolType(i)"
            >
              {{ i.name }}
            </div>
          </div>
        </div>
        <div v-if="petrolAttrInfoList && petrolAttrInfoList.length > 0" class="sku-attr">
          <div class="sku-label">选择油号</div>
          <div class="skus">
            <div
              v-for="(i, j) in petrolAttrInfoList"
              :key="'goods' + j"
              class="sku"
              :class="{
                'choose': petrolId === i.id
              }"
              @click="handleChoosePetrol(i)"
            >
              {{ i.name }}
            </div>
          </div>
        </div>
        <div class="sku-attr">
          <div class="sku-label">全部商品</div>
          <div class="skus">
            <div
              v-for="(i, j) in petrolGuns"
              :key="'goods' + j"
              class="sku"
              :class="{
                'choose': petrolGunId === i.id
              }"
              @click="handleChoosePetrolGun(i)"
            >
              {{ i.name }}
            </div>
          </div>
        </div>
        <!-- 加油提示区域 -->
        <div class="petrol-order-tips">
          <div class="tips-title">重要提示</div>
          <div class="tips-container">
            <p>请务必先到达油站与工作人员确认再付款，切勿先买单后加油,避 免异常订单的产生</p>
            <p>若无您选择的油枪号，请联系油站工作人员支付前请确认加油站 是否正确</p>
          </div>
        </div>
        <!-- 确认选择信息-->
        <div class="confirm-container">
          <van-button color="#F7263C" block @click="handleConfirm">确定</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { ActionSheet as VanActionSheet, Button as VanButton, Toast } from 'vant'
import { fetchGroupRefuelingDetail, getXJComeOnDetail } from '@/services/comeOn'

export default {
  name: 'ChoosePetrolGunOrNumber',
  components: {
    VanActionSheet,
    VanButton
  },
  data() {
    return {
      actionSheetVisible: false,
      petrolTypeId: null,
      petrolGunId: null,
      petrolId: null,
      stationInfo: null,
      refuelingType: null,
      oilName: null,
      petrolAttr: [],
      goods: [],
      prices: []
    }
  },
  computed: {
    // 油枪类型信息
    petrolTypeInfo() {
      const { goods, petrolTypeId } = this
      if (petrolTypeId) {
        const good = goods.filter(i => i.id === petrolTypeId)[0]
        if (good) {
          return good
        }
      }
      return {}
    },
    // 油枪类型对应attr内的key
    petrolTypeKey() {
      return this.petrolTypeInfo.key || ''
    },
    // 油枪属性列表
    petrolAttrInfoList() {
      if (this.petrolTypeKey && this.petrolTypeKey !== '') {
        return this.petrolAttr[this.petrolTypeKey]
      }
      return []
    },

    // 油号属性信息
    petrolInfo() {
      const { petrolAttrInfoList, petrolId } = this
      if (petrolId) {
        const good = petrolAttrInfoList.filter(i => i.id === petrolId)[0]
        if (good) {
          return good
        }
      }
      return {}
    },
    // 油号对应的枪号
    petrolGuns() {
      const { tool = [] } = this.petrolInfo
      return tool
    }
  },
  mounted() {
  },
  methods: {
    /**
     * 处理打开模态框
     * 2021-08-12 11:47:26
     * @author SnowRock
     */
    async handleOpen({ gasId, oilId, oilName, refuelingType, type, petrolTypeId = 1, petrolId, petrolGunId }) {
      this.gasId = gasId
      this.oilId = oilId
      this.refuelingType = refuelingType
      this.oilName = oilName
      this.type = type
      this.petrolTypeId = petrolTypeId
      this.petrolId = petrolId
      this.petrolGunId = petrolGunId
      await this.initInfo()
      this.actionSheetVisible = true
    },
    /**
     * 初始化数据
     * 2021-08-15 14:00:57
     * @author SnowRock
     */
    async initInfo() {
      const { refuelingType } = this

      if (refuelingType === 'xiaoju') {
        await this.getXJComeOnDetail()
      }
      if (refuelingType === 'group') {
        await this.getGroupRefuelingDetail()
      }
    },
    /**
     * 获取团油详情的信息
     * 2021-08-15 13:48:51
     * @author SnowRock
     */
    async getGroupRefuelingDetail() {
      try {
        const { gasId: id, type, oilName: name } = this
        const phone = localStorage.getItem('mobile')
        const mid = localStorage.getItem('uid')
        const params = {
          id,
          phone,
          mid,
          type,
          name
        }
        const res = await fetchGroupRefuelingDetail(params)
        const { code, data } = res
        if (code === 200) {
          const { info, price: prices, attr: petrolAttr, goods } = data
          this.stationInfo = info
          this.petrolAttr = petrolAttr
          this.goods = goods
          this.prices = prices
        }
      } catch (e) {
        console.log('fetchGroupRefuelingDetail', e)
      }
    },
    /**
     * 获取加油详情
     * 2021年08月12日08:55:39
     * @author SnowRock
     */
    async getXJComeOnDetail() {
      const { gasId, oilId, type } = this
      const mobile = localStorage.getItem('mobile')
      const mid = localStorage.getItem('uid')
      await getXJComeOnDetail({
        type,
        oilId,
        gasId,
        mobile,
        mid
      }).then(res => {
        const { code, data } = res
        if (code === 200) {
          const { info, price: prices, attr: petrolAttr, goods } = data
          this.stationInfo = info
          this.petrolAttr = petrolAttr
          this.goods = goods
          this.prices = prices
        }
      })
    },

    /**
     * 选择商品信息
     * 2021-08-12 11:32:03
     * @author SnowRock
     */
    handleChoosePetrolType(info) {
      const { id } = info
      if (this.petrolTypeId !== id) {
        this.petrolTypeId = id
        this.petrolId = null
        this.petrolGunId = null
      }
    },

    /**
     * 选择商品信息
     * 2021-08-12 11:32:03
     * @author SnowRock
     */
    handleChoosePetrol(info) {
      const { id } = info

      if (this.petrolId !== id) {
        this.petrolId = id
        this.petrolGunId = null
      }
    },

    /**
     * 选择商品信息
     * 2021-08-12 11:32:03
     * @author SnowRock
     */
    handleChoosePetrolGun(info) {
      const { id } = info

      if (this.petrolGunId !== id) {
        this.petrolGunId = id
      }
    },

    /**
     * 处理选中信息
     * 2021-08-12 11:38:13
     * @author SnowRock
     */
    handleConfirm() {
      const { petrolGunId, petrolId, petrolTypeId } = this
      if (!petrolTypeId) {
        Toast('请选择商品')
        return
      }
      if (!petrolId) {
        Toast('请选择油号')
        return
      }
      if (!petrolGunId) {
        Toast('请选择枪号')
        return
      }
      this.$emit('onConfirm', {
        petrolGunId, petrolId, petrolTypeId
      })
      this.actionSheetVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.sku-content {
  padding: 0 12px;
  text-align: left;

  .sku-attr {

    .sku-label {
      font-size: 13px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .skus {
      text-align: left;

      .sku {
        width: 80px;
        height: 26px;
        line-height: 26px;
        opacity: 1;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 10px;
        display: inline-block;

        &:nth-of-type(4n) {
          margin-right: 0;
        }

        &.choose {
          color: #F7263C;
          border: 1px solid #f7263c;
        }
      }
    }
  }

  .petrol-order-tips {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
    margin-bottom: 28px;

    .tips-title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .tips-container {
      font-size: 12px;

      p {
        margin-bottom: 5px;
      }
    }
  }

  .confirm-container {
    margin-bottom: 20px;
  }
}
.choose-petrol-gun-number {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2000;
}

</style>
<style>
.choose-petrol-gun-number .van-popup {
  z-index: 22222222!important;
}
</style>
