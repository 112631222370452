<template>
  <div class="app-container refueling">
    <!-- 站点信息 -->
    <div class="refueling-container">
      <div v-if="stationInfo" class="refueling-station">
        <!-- 站点名称 -->
        <div class="station-name">
          <div class="name">{{ stationInfo.name }}</div>
          <div class="help" @click="handleOpenHelp">帮助&客服</div>
        </div>
        <!-- 站点其他信息 -->
        <div class="station-other-info">
          <div class="station-address">
            {{ stationInfo.address }}
          </div>
          <div class="station-location" @click="navigation">
            <img class="navigation-icon" src="@/assets/images/refueling/navigation-icon.png" alt="导航图标">
            {{ distance }}
          </div>
        </div>
      </div>
      <!-- 油价信息 -->
      <div v-if="petrolTypeInfo" class="petrol-container">
        <!-- 油号 -->
        <div v-if="petrolNumber" class="petrol-number">{{ petrolNumber }}</div>
        <!-- 团油价 -->
        <div v-if="petrolPriceYfq" class="petrol-group-price">
          <div class="label">团油价</div>
          <div class="price-content">￥<span class="price">{{ petrolPriceYfq }}</span></div>
        </div>
        <!-- 油站价 -->
        <div v-if=" petrolPriceGun " class="petrol-station-price">
          <div class="label">油站价</div>
          <div class="price-content">￥<span class="price">{{ petrolPriceGun }}</span></div>
        </div>
        <!-- 指导价 -->
        <div v-if="petrolPriceOfficial" class="petrol-recommend-number">
          <div class="label">指导价</div>
          <div class="price-content">￥<span class="price">{{ petrolPriceOfficial }}</span></div>
        </div>
      </div>
    </div>

    <!-- 加油订单信息区域 -->
    <div class="skus-container" :style="refuelingType === 'xiaoju' ? '' : 'padding-bottom: 15px'">
      <!-- 油号 油枪号选择区域 -->
      <div
        class="choose-content petrol"
        :class="{
          'is-not-choose-gun': refuelingType === 'xiaoju' ? !petrolGunId : true
        }"
        @click="handleChooseGunOrPetrol"
      >
        <div class="oil-gun">
          <div class="label-icon"><img src="@/assets/images/refueling/oli-gun-icon.png" alt=""></div>
          <div class="label">
            {{ !petrolGunId ? '请选择枪号' : (petrolGunName + '油枪') }}
            <span><van-icon name="play" /></span>
          </div>
        </div>
        <div class="petrol-skus">
          <div class="label-icon"><img src="@/assets/images/refueling/petrol-icon.png" alt=""></div>
          <div class="label">
            {{ petrolNumber || '请选择油号' }}
            <span><van-icon name="play" /></span>
          </div>
        </div>
      </div>
      <!-- 金额输入区域 -->

      <div
        v-if="refuelingType === 'xiaoju'"
        class="choose-content price"
        :class="{
          'is-not-choose-gun': !petrolGunId
        }"
      >
        <div
          class="order-price"
          :class="{
            'not-empty': isHasPrice
          }"
        >
          <span>￥</span><input v-model="orderPrice" :disabled="!petrolGunId" type="number" :placeholder="orderPricePlaceholder" @input="handlePriceChange">
        </div>
        <div class="recommend-price-content">
          <div v-for="(i, j) in priceList" :key="j" class="recommend-price" @click="petrolGunId ? handleChoosePrice(i.amount) : ''">
            <div class="price">￥{{ i.amount }}</div>
            <div class="discount-amount">
              可优惠￥{{ i.promotionAmount }}
            </div>
          </div>
        </div>
      </div>
      <!-- 订单优惠信息区域 -->

      <van-collapse v-if="refuelingType === 'xiaoju'" v-model="activeName" accordion>
        <van-collapse-item name="1" :value="discountAmountTips" :is-link="isHasPrice">
          <div slot="title">
            <img class="coupon-icon" src="@/assets/images/refueling/coupon-icon.png" alt="" @click.stop.prevent="isHasPrice ? handleOpenChooseCoupon() : ''">
            <span class="coupon-label">优惠券</span>
            <span v-if="isHasPrice" class="coupon-sub-label">
              已为您默认选择最高优惠
            </span>
          </div>
          <div v-if="isHasPrice">
            <div class="cell">
              <div class="cell-label">优惠直降</div>
              <div class="cell-value">-￥{{ promotionAmount || '0' }}</div>
            </div>
            <div class="cell">
              <div class="cell-label">奖励</div>
              <div class="cell-value">-￥{{ nConsumReward || '0' }}</div>
            </div>
            <div class="cell">
              <div class="cell-label">渠道服务费 <img class="channel-service-icon" src="@/assets/images/refueling/channel-service-icon.png" alt="渠道服务费" @click="handleToastTipsByChannelService"></div>
              <div class="cell-value">￥{{ serviceFee || '0' }}</div>
            </div>
            <div class="cell" @click="handleOpenChooseCoupon">
              <div class="cell-label">优惠券</div>
              <div class="cell-value">{{ promotionDesc || '0' }}</div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 加油提示区域 -->
    <div v-if="tips" class="petrol-order-tips">
      <div class="tips-title">{{ tips.title }}</div>
      <div class="tips-container">
        <p v-for="(i, j) in tips.explain" :key="j">
          {{ i }}
        </p>
      </div>
    </div>
    <van-submit-bar
      v-if="refuelingType === 'xiaoju'"
      :price="payPrice * 100"
      text-align="left"
      :suffix-label="suffixLabelTips"
      button-color="#F7263C"
      button-text="去支付"
      :disabled="isSubmit"
      @submit="onSubmit"
    />
    <!-- 团油跳转 -->
    <div v-if="refuelingType === 'group'" class="group-button-btn">
      <van-button color="#F7263C" block :disabled="isGroupBuyDisabled" @click="handleGroupRefueling">立即加油</van-button>
    </div>
    <!--  加油选择枪号油号  -->
    <ChoosePetrolGunOrNumber ref="ChoosePetrolGunOrNumber" @onConfirm="handleConfirmChooseInfo" />
    <!-- 优惠券 -->
    <RefuelingCouponChoose v-if="refuelingType === 'xiaoju'" ref="RefuelingCouponChoose" @handleChooseCoupon="handleChooseCoupon" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import {
  Collapse as VanCollapse,
  CollapseItem as VanCollapseItem,
  SubmitBar as VanSubmitBar,
  Button as VanButton,
  Icon as VanIcon, Toast, Dialog
} from 'vant'
import {
  fetchChannelServiceTips,
  fetchGroupRefuelingDetail, fetchGroupRefuelingPay,
  getXJComeOnDetail,
  getXJComeOnPayData,
  getXJComeOnSubmitOrders
} from '@/services/comeOn'
import ChoosePetrolGunOrNumber from '@/views/localLife/refueling/confirm-detail/components/ChoosePetrolGunOrNumber'
import { setLocal, throttle } from '@/utils/common/utils'
import { getModulePaymentInfo, weChatPay } from '@/utils/payment'
import { isWeChat, isWeChatApplet } from '@/utils/common/validate'
import RefuelingCouponChoose from '@/views/localLife/refueling/confirm-detail/components/RefuelingCouponChoose'
import { getusernew } from '@/services/userApi'

export default {
  name: 'RefuelingConfirmDetail',
  components: {
    RefuelingCouponChoose,
    ChoosePetrolGunOrNumber,
    VanCollapse,
    VanCollapseItem,
    VanSubmitBar,
    VanButton,
    VanIcon
  },
  data() {
    return {
      gasId: null, // 油编号id
      oilId: null, // id
      type: null, // id
      show: false,
      orderPrice: '', // 油价
      payPrice: 0, // 支付金额
      activeName: '1', // 展开收起优惠的列表
      stationInfo: '', // 站点信息
      petrolAttr: {}, // 油价列表
      goods: [], // 商品规格列表
      tips: null, // 提示语信息
      prices: [], // 推荐价格列表
      priceList: [], // 推荐价格列表(新版)
      petrolTypeId: null, // 选中的汽油类型id
      petrolId: null, // 选中的汽油油号id
      petrolGunId: null, // 选中的油枪id
      litre: null, // 购买的油数量/L
      promotionAmount: null, // 优惠直降
      serviceFee: null, // 渠道服务费
      couponsPrice: null, // 优惠价格
      nConsumReward: null, // 奖励
      consumeReward: null, // 优惠
      discountAmount: null, // 总优惠金额
      allowanceId: null, // 优惠券id
      allowanceInfo: null, // 优惠券信息
      orderNo: null, // 支付的订单号

      oilName: '', // 油名称
      refuelingType: '' // 油的类型 xiaoju 小桔加油； group：团油；
    }
  },
  computed: {
    // 优惠券信息
    promotionDesc() {
      const { allowanceInfo } = this
      if (allowanceInfo) {
        const { promotionDesc } = allowanceInfo

        if (promotionDesc) {
          return promotionDesc
        }
      }
      return '未使用优惠券'
    },
    // 加油金额输入框提示语
    orderPricePlaceholder() {
      const { petrolGunId } = this

      if (!petrolGunId) {
        return '请先选择油枪号，再输入金额'
      }
      return '请输入加油金额'
    },
    /**
     * 优惠券提示信息
     * 2021-08-12 13:46:01
     * @author SnowRock
     */
    discountAmountTips() {
      const { isHasPrice, discountAmount } = this
      if (isHasPrice) {
        if (discountAmount) {
          return `-￥${discountAmount}`
        }
        return ''
      }
      return '输入金额后查看适用优惠券'
    },
    /**
     * 优惠券提示信息
     * 2021-08-12 13:46:01
     * @author SnowRock
     */
    couponsPriceTips() {
      const { couponsPrice, isHasPrice } = this
      if (isHasPrice) {
        if (couponsPrice) {
          return `-￥${couponsPrice}`
        }
      }
      return '未使用优惠券'
    },
    /**
     * 是否有金额
     */
    isHasPrice() {
      const { orderPrice } = this
      return !!(orderPrice && orderPrice !== '')
    },
    // 油枪类型名称
    isSubmit() {
      return !this.isHasPrice
    },
    // 是否能立即加油
    isGroupBuyDisabled() {
      const { petrolGunId, petrolId } = this
      return Boolean(!(petrolGunId && petrolGunId !== '' && petrolId && petrolId !== ''))
    },
    /**
     * 底部优惠提示语
     * 2021-08-12 13:34:19
     * @author SnowRock
     */
    suffixLabelTips() {
      const { discountAmount, isHasPrice } = this
      if (isHasPrice) {
        if (discountAmount) {
          return ` 已优惠 -￥${discountAmount}`
        }
      }
      return ''
    },
    // 油枪类型信息
    petrolTypeInfo() {
      const { goods, petrolTypeId } = this
      if (petrolTypeId) {
        const good = goods.filter(i => i.id === petrolTypeId)[0]
        if (good) {
          return good
        }
      }
      return {}
    },
    // 油枪类型对应attr内的key
    petrolTypeKey() {
      return this.petrolTypeInfo.key || ''
    },
    // 汽油规格列表
    petrolAttrInfoList() {
      if (this.petrolTypeKey && this.petrolTypeKey !== '') {
        return this.petrolAttr[this.petrolTypeKey]
      }
      return []
    },
    // 油号属性信息
    petrolInfo() {
      const { petrolAttrInfoList, petrolId } = this
      if (petrolId) {
        const good = petrolAttrInfoList.filter(i => i.id === petrolId)[0]
        if (good) {
          return good
        }
      }
      return {}
    },
    // 油号名称
    petrolNumber() {
      const { petrolInfo } = this
      return petrolInfo.name || ''
    },
    // 油号价格 团油
    petrolPriceGun() {
      const { priceGun = '' } = this.petrolInfo
      return priceGun
    },
    // 油号价格 油站价格
    petrolPriceOfficial() {
      const { priceOfficial = '' } = this.petrolInfo
      return priceOfficial
    },
    // 油号价格 指导价格
    petrolPriceYfq() {
      const { priceYfq = '' } = this.petrolInfo
      return priceYfq
    },
    // 油号对应的枪号
    petrolGuns() {
      const { tool = [] } = this.petrolInfo
      return tool
    },
    // 油枪名称
    petrolGunName() {
      const { name = '' } = this.petrolGunInfo
      return name !== '' ? (name) : ''
    },
    // 油枪属性
    petrolGunInfo() {
      const { petrolGunId, petrolGuns } = this
      if (petrolGunId) {
        const gunInfo = petrolGuns.filter(i => i.id === petrolGunId)[0]
        if (gunInfo) {
          return gunInfo
        }
      }
      return {}
    }
  },
  async created() {
    const { gasId, oilId, type, distance, oilName, refuelingType } = this.$route.query
    this.oilName = oilName
    this.refuelingType = refuelingType
    this.gasId = gasId
    this.oilId = oilId
    this.type = type
    this.distance = distance
    if (refuelingType === 'group' && isWeChat()) {
      Dialog.alert({
        title: '提示',
        message: '请点击右上角后，点击浏览器打开进行支付',
        confirmButtonText: '我知道了'
      })
    }
    await this.getUserInfo()
    this.initDetailInfo()
  },
  methods: {

    /**
     * 获取用户信息
     * 2021-08-16 16:42:51
     * @author SnowRock
     */
    async getUserInfo() {
      await getusernew().then(res => {
        const { code, data } = res
        if (code === 200) {
          const { mobile, reCode } = data
          setLocal('recode', reCode)
          setLocal('mobile', mobile)
        }
      })
    },
    /**
     * 初始化详情信息
     * 2021-08-15 13:46:44
     * @author SnowRock
     */
    initDetailInfo() {
      const { refuelingType } = this

      if (refuelingType === 'xiaoju') {
        this.getXJComeOnDetail()
      }
      if (refuelingType === 'group') {
        this.getGroupRefuelingDetail()
      }
    },
    /**
     * 获取团油详情的信息
     * 2021-08-15 13:48:51
     * @author SnowRock
     */
    async getGroupRefuelingDetail() {
      try {
        const { gasId: id, type, oilName: name } = this
        const phone = localStorage.getItem('mobile')
        const mid = localStorage.getItem('uid')
        const params = {
          id,
          phone,
          mid,
          type,
          name
        }
        const res = await fetchGroupRefuelingDetail(params)
        const { code, data } = res
        if (code === 200) {
          const { info, price: prices, attr: petrolAttr, priceList, goods, tips } = data
          this.stationInfo = info
          this.petrolAttr = petrolAttr
          this.goods = goods
          this.prices = prices
          this.tips = tips
          this.priceList = priceList
          // 如果没有默认汽油类型则选择默认第一个类型
          if (!(this.petrolTypeId && this.petrolTypeId !== '')) {
            // 此处疑问， 燃油类型与id是否保持一致？？？？？？？？ !!!!!!!!!!!!!!!!!
            const info = this.goods.filter(i => type && i.id === type)[0]
            if (info) {
              this.petrolTypeId = info.id
            } else {
              this.petrolTypeId = goods[0].id
            }
          }
          this.$nextTick(() => {
            // 如果没有默认油号获取第一个默认油号
            if (!(this.petrolNumber && this.petrolNumber !== '')) {
              const info = this.petrolAttrInfoList.filter(i => name && i.name === name)[0]
              if (info) {
                this.petrolId = info.id
              } else {
                this.petrolId = this.petrolAttrInfoList[0].id
              }
            }
          })
        }
      } catch (e) {
        console.log('fetchGroupRefuelingDetail', e)
      }
    },
    /**
     * 获取加油详情
     * 2021年08月12日08:55:39
     * @author SnowRock
     */
    getXJComeOnDetail() {
      const { gasId, oilId, type } = this
      const mobile = localStorage.getItem('mobile')
      const mid = localStorage.getItem('uid')
      getXJComeOnDetail({
        type,
        oilId,
        gasId,
        mobile,
        mid
      }).then(res => {
        const { code, data } = res
        if (code === 200) {
          const { info, price: prices, attr: petrolAttr, priceList, goods, tips } = data
          this.stationInfo = info
          this.petrolAttr = petrolAttr
          this.goods = goods
          this.prices = prices
          this.tips = tips
          this.priceList = priceList

          // 如果没有默认汽油类型则选择默认第一个类型
          if (!(this.petrolTypeId && this.petrolTypeId !== '')) {
            // 此处疑问， 燃油类型与id是否保持一致？？？？？？？？ !!!!!!!!!!!!!!!!!
            const info = this.goods.filter(i => type && i.id === type)[0]
            if (info) {
              this.petrolTypeId = info.id
            } else {
              this.petrolTypeId = goods[0].id
            }
          }
          this.$nextTick(() => {
            // 如果没有默认油号获取第一个默认油号
            if (!(this.petrolNumber && this.petrolNumber !== '')) {
              const info = this.petrolAttrInfoList.filter(i => oilId && i.name === (oilId + '#'))[0]
              if (info) {
                this.petrolId = info.id
              } else {
                this.petrolId = this.petrolAttrInfoList[0].id
              }
            }
          })
        }
      })
    },
    /**
     * 处理打开选择油枪和油号的模态框
     * 2021-08-12 10:22:40
     * @author SnowRock
     */
    handleChooseGunOrPetrol() {
      const { gasId, oilId, oilName, type, petrolTypeId, petrolId, refuelingType, petrolGunId } = this

      this.$refs.ChoosePetrolGunOrNumber.handleOpen({
        gasId,
        oilId,
        oilName,
        refuelingType,
        type,
        petrolTypeId,
        petrolId,
        petrolGunId
      })
    },
    /**
     * 处理打开选择油枪和油号的模态框
     * 2021-08-12 10:22:40
     * @author SnowRock
     */
    handleConfirmChooseInfo({ petrolTypeId, petrolId, petrolGunId }) {
      this.petrolTypeId = petrolTypeId
      this.petrolId = petrolId
      this.petrolGunId = petrolGunId
      this.initDetailInfo()
      this.handlePriceChange()
    },
    /**
     * 处理打开帮助客服信息
     * 2021-08-12 13:51:48
     * @author SnowRock
     */
    handleOpenHelp() {
      this.$router.push({
        path: '/comeOnHelp'
      })
    },
    /**
     * 处理购买油
     * 2021-08-15 14:09:20
     * @author SnowRock
     */
    handleGroupRefueling() {
      const { gasId: id, petrolGunId: gunNo } = this
      const phone = localStorage.getItem('mobile')
      const mid = localStorage.getItem('uid')

      const params = {
        phone,
        mid,
        gunNo,
        id
      }
      fetchGroupRefuelingPay(params).then(res => {
        const { code, data } = res
        if (code === 200) {
          window.location.href = data.url
        }
      })
    },
    /**
     * 处理选择金额
     * 2021-08-12 17:03:51
     * @author SnowRock
     */
    handleChoosePrice(price) {
      if (price !== this.orderPrice) {
        this.orderPrice = price
        this.handlePriceChange()
      }
    },
    /**
     * 处理金额改变
     * 2021-08-12 14:41:05
     * @author SnowRock
     */
    handlePriceChange: throttle(function() {
      const { isHasPrice } = this
      if (isHasPrice) {
        this.getXJComeOnPayData()
      } else {
        this.promotionAmount = null
        this.serviceFee = null
        this.couponsPrice = null
        this.payPrice = null
        this.nConsumReward = null
        this.consumeReward = null
        this.litre = null
      }
    }, 500),
    /**
     * 获取确认订单信息
     * 2021-08-12 14:35:28
     * @author SnowRock
     */
    getXJComeOnPayData() {
      const { gasId: storeId, orderPrice: amount, petrolNumber: name, allowanceId } = this
      const mobile = localStorage.getItem('mobile')
      const mid = localStorage.getItem('uid')
      getXJComeOnPayData({
        storeId, // 油站编号
        mid, // 用户id
        mobile, // 用户手机号
        amount, // 金额（元）
        name, // 油号名称
        allowanceId // 优惠券信息
      }).then(res => {
        const { code, data } = res
        if (code === 200) {
          const { payAmount, promotionAmount, nConsumReward, litre, discount, consumeReward, couponsPrice, serviceFee } = data
          this.promotionAmount = promotionAmount
          this.serviceFee = serviceFee
          this.couponsPrice = couponsPrice
          this.payPrice = payAmount
          this.nConsumReward = nConsumReward
          this.consumeReward = consumeReward
          this.discountAmount = discount
          this.litre = litre
        }
      })
    },
    /**
     * 处理选择器选中优惠券
     * 2021-08-13 11:28:26
     * @author SnowRock
     */
    handleOpenChooseCoupon() {
      const { gasId, orderPrice: amount, allowanceId: couponId } = this
      this.$refs.RefuelingCouponChoose.handleOpen({
        gasId,
        amount,
        couponId
      })
    },
    /**
     * 处理选择器选中优惠券
     * 2021-08-13 11:28:26
     * @author SnowRock
     */
    handleChooseCoupon({ couponId, ...allowanceInfo }) {
      this.allowanceId = couponId
      this.allowanceInfo = allowanceInfo
      this.getXJComeOnPayData()
    },
    /**
     * 渠道服务费提示语
     * 2021-08-15 15:10:11
     * @author SnowRock
     */
    handleToastTipsByChannelService() {
      fetchChannelServiceTips().then(res => {
        const { code, data } = res
        if (code === 200) {
          const { title, content } = data
          Dialog.alert({
            title: title,
            message: content
          }).then(() => {
            // on close
          })
        }
      })
    },
    /**
     * 处理提交信息
     * 2021-08-12 08:58:05
     * @author SnowRock
     */
    onSubmit() {
      this.getCreateOilOrder()
    },

    // 加油提交订单
    getCreateOilOrder() {
      const {
        gasId,
        allowanceId,
        litre,
        orderPrice: amountGun,
        petrolId: oilNumberId,
        payPrice: payAmount,
        petrolGunId: gunNo,
        petrolNumber: oilName,
        petrolPriceYfq: priceYfq,
        petrolPriceGun: priceGun,
        petrolInfo: { oilType },
        stationInfo: { name: gasName, id: gasKeyId }
      } = this
      const params = {
        gasId, // 小桔油站ID
        gasName, // 油站名称
        gasKeyId, // 中台油站ID
        oilNumberId, // 油号ID
        oilName, // 油号名称
        payAmount, // 付款金额
        litre, // 油升数量
        amountGun, // 订单总金额
        payType: 1, // 支付方式 H5公众号支付传1
        gunNo, // 枪号
        // other_gas_id=4879454965540848671&number=4879454965540848671&name=92&gun_no=2&oil_type=1&price_gun=7.21&price_unit=6.8&pricelist=100&pricelist=200&pricelist=300&pricelist=400
        oilType, // 燃油类型 汽油 柴油天然气
        priceGun, // 门店枪价
        priceYfq, // 三方优惠枪价
        allowanceId // 优惠券信息
      }
      // console.log('小桔加油提交信息', params)
      getXJComeOnSubmitOrders(params).then((res) => {
        if (Number(res.code) === 200) {
          this.orderNo = res.data.orderNo
          if (isWeChatApplet()) {
            wx.miniProgram.navigateTo({
              url: `/page/payment/choose/index?orderNo=${res.data.orderNo}&moduleId=5`
            })
          } else {
            // this.payForRefueling()
            this.$router.push({
              path: '/pay',
              query: {
                orderNo: res.data.orderNo,
                goodsId: '',
                moduleType: 5
              }
            })
            console.log(res.data)
          }
        }
      })
    },
    /**
     * 调用微信支付
     * 2021-06-04 10:22:15
     * @author SnowRock
     * @param params
     */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
        that.isOrderType('TRADE_SUCCESS')
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            that.isOrderType('UNKNOWN')
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            that.isOrderType('UNKNOWN')
          }
        }
        that.isDisabledSubmitBtn = false
      })
    },
    // 加油发起支付
    payForRefueling() {
      const openId = localStorage.getItem('openid')
      getModulePaymentInfo(4, 5, {
        orderNo: this.orderNo,
        openId
      }).then(res => {
        const params = res.data.getwayBody
        this.payFormNo = res.data.payFormNo
        this.wxPay(params)
      }).catch(err => {
        console.log(err)
      })
    },
    isOrderType(status) {
      const that = this
      // 区分拼团订单接口携带参数和其他订单接口携带参数
      that.$router.push({
        path: '/oderSky',
        query: {
          payType: 2,
          payFormNo: this.orderNo,
          status: status
        }
      })
    },

    // 导航
    navigation(item) {
      const { lat, lng, name, address } = this.stationInfo
      if (isWeChatApplet()) {
        wx.openLocation({
          latitude: Number(lat), // 纬度，浮点数，范围为90 ~ -90
          longitude: Number(lng), // 经度，浮点数，范围为180 ~ -180。
          name: name, // 位置名
          address: address, // 地址详情说明
          scale: 20 // 地图缩放级别,整型值,范围从1~28。默认为最大
        })
      } else {
        window.location.href = 'http://apis.map.qq.com/uri/v1/marker?marker=coord:' + Number(lat) + ',' + Number(lng) + ';title:' + name + ';addr:' + address
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-container.refueling {
  text-align: left;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-bottom: 100px;

  ::v-deep {
    .van-submit-bar__text {
      color: #333;
    }

    .van-submit-bar__price {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium, sans-serif;
      color: #333333;
      font-weight: 500;
    }

    .van-submit-bar__suffix-label {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular, sans-serif;
      font-weight: 400;
      color: #f7263c;
      margin-left: 10px;
    }
  }

  .refueling-container {
    padding: 10px 12px;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 5px;

    .refueling-station {
      margin-bottom: 15px;

      .station-name {
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;
        display: flex;
        line-height: 28px;

        .name {
          flex: auto;
          font-size: 20px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .help {
          min-width: 70px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          margin-left: 10px;
          text-align: center;
          color: #333333;
        }
      }

      .station-other-info {
        display: flex;
        align-items: center;

        .station-address {
          flex: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          color: #666666;
          font-weight: 400;
        }

        .station-location {

          min-width: auto;
          height: 28px;
          padding: 0 8px;
          line-height: 28px;
          text-align: center;
          opacity: 1;
          font-size: 12px;
          border: 1px solid #eeeeee;
          border-radius: 15px;
          white-space: nowrap;
          margin-left: 10px;
          .navigation-icon {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .petrol-container {
      height: 61px;
      background: #f8f8f8;
      border-radius: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #333333;

      .petrol-number {
        padding: 0 36px;
      }

      .petrol-group-price {
        flex: auto;
        color: #F7263C;
      }

      .petrol-station-price {
        flex: auto;
      }

      .petrol-recommend-number {
        flex: auto;
      }

      .label {
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        line-height: 17px;
      }

      .price-content {
        font-size: 8px;
        line-height: 20px;
        margin-top: 4px;

        .price {
          font-size: 14px;
        }
      }
    }
  }

  .skus-container {
    padding: 20px 12px 0px;
    background-color: #fff;

    .choose-content {
      background: #f8f8f8;
      border: 1px solid #dddddd;
      border-radius: 6px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      font-family: PingFangSC, PingFangSC-Regular;

      & > div {
        display: flex;
        align-items: center;
      }

      & + .choose-content {
        margin-top: 10px;
      }

      &.petrol {
        /* 未选中油枪 选择油枪区域 */

        &.is-not-choose-gun {
          color: #F7263C;
          background-color: #fff5f6;
          border: 1px solid #F7263C;
        }

        & > div {
          padding: 18px;
          align-items: center;
          justify-content: center;
        }

        .label-icon {
          width: 26px;
          height: 26px;
          margin-right: 7px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .label {
          .van-icon {
            font-size: 6px;
            transform: rotateZ(90deg) rotateY(40deg) translateX(1px);
          }
        }
      }

      &.price {
        color: #F7263C;
        border: 1px solid #F7263C;
        background-color: #fff5f6;
        /* 未选中油枪 输入价格区域 */

        &.is-not-choose-gun {
          color: #999999;
          border: 1px solid #dddddd;
          background-color: #F8F8F8;
        }

        .order-price {
          flex: auto;
          padding-left: 20px;
          align-items: center;
          border-right: 1px solid #dddddd;

          &.not-empty {
            font-size: 24px;
            font-family: PingFangSC, PingFangSC-Medium, sans-serif;
            font-weight: 500;
            text-align: center;
            color: #333333;
            line-height: 33px;
            letter-spacing: 0px;

            input {
              letter-spacing: 0px;
            }

            span {
              opacity: 1;
              line-height: 109px;
            }
          }

          span {
            font-size: 24px;
            opacity: 0;
            margin-right: 0;
          }

          input {
            width: 100%;
            background-color: transparent;
            height: 109px;
            line-height: 109px;
            border: none;
            outline: none;
            padding: 0;
            margin: 0;

            &::placeholder {
              height: 109px;
              line-height: 109px;
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .recommend-price-content {
          width: 81px;
          min-width: 81px;
          flex-flow: column;
          text-align: center;

          .recommend-price {
            width: 100%;
            font-size: 14px;
            flex: 1;
            display: flex;
            align-items: center;
            flex-flow: column;
            justify-content: center;

            & + .recommend-price {
              border-top: 1px solid #dddddd;
            }

            .price {
              line-height: 20px;
            }

            .discount-amount {
              font-size: 10px;
            }
          }
        }
      }

      .oil-gun {
        flex: 1;
        text-align: center;
      }

      .petrol-skus {
        flex: 1;
        text-align: center;
      }
    }

    ::v-deep .van-cell__title {
      white-space: nowrap;
    }

    .coupon-icon {
      width: 23px;
      height: 23px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }

    .coupon-label {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }

    .coupon-sub-label {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      color: #999999;
    }

    ::v-deep .van-cell {
      padding-left: 0;
      padding-right: 0;
    }

    ::v-deep .van-cell__value {
      width: auto;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }

    ::v-deep .van-collapse-item__title--expanded::after {
      display: none;
    }

    ::v-deep .van-collapse-item__content {
      padding: 0;
      margin: 0;

      .cell {
        font-size: 12px;
        color: #333333;
        line-height: 32px;
        display: flex;
        justify-content: space-between;

        .cell-value {
          color: #666666;
        }
        .channel-service-icon {
          width: 12px;
          height: 12px;
          transform: translateY(1px);
          margin-left: 2px;
        }
      }
    }
  }

  .petrol-order-tips {
    font-family: PingFangSC, PingFangSC-Regular, sans-serif;
    font-weight: 400;
    color: #333333;
    padding: 21px 12px 0px;

    .tips-title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .tips-container {
      font-size: 12px;

      p {
        margin-bottom: 5px;
      }
    }
  }

  .group-button-btn {
    width: calc(100% - 30px);
    margin: 94px auto 0;
  }
}
</style>
